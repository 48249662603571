.modalWrapper {
  display: flex;
  flex-direction: row;
}

.actionContainer {
  width: 100%;
  margin: 8px 0 8px 0;
  display: flex;
  flex: 1 0 8px;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.buttonContainer {
  display: flex;
  margin: 0 0 0 8px;
  position: relative;
};
