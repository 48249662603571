.arrow:hover {
  cursor: pointer;
  background-color: rgb(245,245,245);
  transition: background-color 500ms;
  border-radius: 2px;
}

.title {
  font-weight: 500;
  font-size: large;
}

.body {
  background-color: rgb(245,245,245);
  opacity: 0.9;
  padding: 16px;
}

.root {
  border-radius: 2px;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.40);
}