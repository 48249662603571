.side_bar_style  {
  display: flex;
  flex-direction: column;
  width: 20%;
  height: 100%;
  background-color: #fff;
  transition: transform 0.2s ease-out;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  overflow-y: scroll;
}

.side_bar_close_style {
  transform: translateX(100%);
}

.side_bar_footer_style {
  display: flex;
  justify-content: space-between;
}

.side_bar_content {
  flex-grow: 1;
}