.katal kat-table kat-table-body kat-table-row:hover {
  background-color: #fafafa;
}
.katal kat-table kat-table-body kat-table-row {
  min-height: 18px;
}
.katal kat-table-cell p {
  margin: 0;
}
.katal kat-table kat-table-body.empty {
  height: 200px;
  display: table;
}
.katal kat-table.loading #document-listing-table-row {
  opacity: 0.75;
}
.data-table-listing-loading,
.data-table-listing-empty,
.data-table-listing-error {
  position: absolute;
  top: 43px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 130, 150, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
