.data_table_anchored_cell {
  padding: 0 !important;
  vertical-align: top;

  p {
    background: white;
    width: 100%;
    height: 100%;
    padding: 15px;
  }

  p:empty {
    min-height: 50px;
  }

  div:empty {
    background: white;
    min-height: 50px;
    height: 30px;
    width: 100%;
  }
}
.data_table_anchor {
  text-decoration: none !important;
  color: #002F36 !important;
}
.linkable_cell_content {
  padding: 15px;
  width: 100%;
  height: 100%;
}
