.absolute {
  position: absolute;
}
.details_tab {
  width: 10%;
  --label-padding: 12px;
  --label-underline-height-selected: 1px;
  --label-focus-ring-outlay: 0px
}
.align_items_stretch {
  align-items: stretch;
}
.align_items_center {
  align-items: center;
}
.align_items_start {
  align-items: flex-start;
}
.align_items_end {
  align-items: end;
}
.align_self_flex_start {
  align-self: flex-start;
}
.align_self_flex_end {
  align-self: flex-end;
}
.direction_inherit {
  direction: inherit;
}
.display_inline_flex {
  display: inline-flex;
}
.display_flex {
  display: flex;
}
.display_block {
  display: block;
}
.flex_direction_column {
  flex-direction: column;
}
.flex_direction_column_reverse {
  flex-direction: column-reverse;
}
.flex_direction_row {
  flex-direction: row;
}
.flex_direction_row_reverse {
  flex-direction: row-reverse;
}
.flex_nowrap {
  flex-wrap: nowrap;
}
.flex_initial {
  flex: initial;
}
.relative {
  position: relative;
}
.static {
  position: static;
}
.right_zero {
  right: 0;
}
.right_0 {
  right: 0;
}
.left_0 {
  left: 0;
}
.left_50 {
  left: 50px;
}
.left_200 {
  left: 200px;
}
.justify_center {
  justify-content: center;
}
.justify_end {
  justify-content: flex-end;
}
.justify_start {
  justify-content: flex-start;
}
.justify_space_between {
  justify-content: space-between;
}
.items_center {
  align-items: center;
}
.pointer {
  cursor: pointer;
}
.ml_10 {
  margin-left: 10px;
}
.doc_page_copy_document_id_icon[name="checkmark"] {
  --color: green;
}
.mb_zero_important {
  margin-bottom: 0px !important;
}
.position_fixed {
  position: fixed;
}
.tex_aligned_center {
  text-align: center;
}
.top_zero {
  top: 0;
}
.top_56 {
  top: 56px;
}
.left_zero {
  left: 0;
}
.z_index_max {
  z-index: 999;
}
.padding_8 {
  padding: 8px;
}
.padding_10 {
  padding: 10px;
}
.pt_16 {
  padding-top: 16px;
}
.pl_16 {
  padding-left: 16px;
}
.pl_4rem {
  padding-left: 4rem;
}
.pr_16 {
  padding-right: 16px;
}
.ml_8 {
  margin-left: 8px;
}
.ml_16 {
  margin-left: 16px;
}
.mr_8 {
  margin-right: 8px;
}
.mr_16 {
  margin-right: 16px;
}
.mt_6 {
  margin-top: 6px;
}
.mt_neg_23 {
  margin-top: -23px;
}
.mt_8 {
  margin-top: 8px;
}
.mt_16 {
  margin-top: 16px;
}
.mt_32 {
  margin-top: 32px;
}
.m_auto {
  margin: auto;
}
.m_auto {
  margin: auto;
}
.ma_6 {
  margin: 6px;
}
.ma_8 {
  margin: 8px;
}
.ma_16 {
  margin: 16px;
}
.ma_10 {
  margin: 10px;
}
.max_h_600 {
  max-height: 600px;
}
.max_w_15 {
  max-width: 15px;
}
.max_w_400 {
  max-width: 400px;
}
.min_w_25 {
  min-width: 25%;
}
.min_w_40 {
  min-width: 40%;
}
.w_fixed_250_px {
  width: 250px;
}
.w_4 {
  width: 4%;
}
.w_10 {
  width: 10%;
}
.w_11 {
  width: 11%;
}
.w_15 {
  width: 15%;
}
.min_w_15 {
  min-width: 15%;
}
.obj_fit_cover {
  object-fit: cover;
}
.w_fixed_35 {
  width: 35px;
}
.h_fixed_35 {
  height: 35px;
}
.ws_no_wrap {
  white-space: nowrap;
}
.w_20 {
  width: 20%;
}
.w_25 {
  width: 25%;
}
.w_30 {
  width: 30%;
}
.w_35 {
  width: 35%;
}
.w_40 {
  width: 40%;
}
.w_45 {
  width: 45%;
}
.w_50 {
  width: 50%;
}
.w_60 {
  width: 60%;
}
.w_65 {
  width: 65%;
}
.w_70 {
  width: 70%;
}
.w_70 {
   width: 70%;
}
.w_95_px {
  width: 95px;
}
.w_80 {
  width: 80%;
}
.w_85 {
  width: 85%;
}
.min_w_85 {
  min-width: 85%;
}
.w_90 {
  width: 90%;
}
.w_100 {
  width: 100%;
}
.katal_dropdown_option_padding_02rem {
  --option-padding: 0.5rem 0.2rem;
}
.language_picker_dropdown_width {
  width: 102px;
}
.w_150_px {
  width: 150px;
}
.w_210_px {
  width: 210px;
}
.w_450_px {
  width: 450px;
}
.w_550_px {
  width: 550px;
}
.h_30 {
  height: 30%;
}
.h_50 {
  height: 50%;
}
.h_55_px {
  height: 55px;
}
.flex {
  display: flex;
}
.capitalizedText:first-letter {
  text-transform: capitalize;
}
.bold {
  font-weight: bold;
}
.b_radius_50 {
  border-radius: 50%;
}
.b_2_solid {
  border: 1px #FFC400 solid
}
.b_l_solid {
  border-left: 1px #d5dbdb solid
}
.box_shadow {
  box-shadow: 0 1px 3px 0 #b5b5b5;
}
.card_border {
  border: 1px solid #d5dbdb;
}
.background_white {
  background-color: white;
}
.border_top {
  border-top: 1px solid #d5dbdb;
}
.kat_component_bold_label {
  --kat-label-font-weight: bold;
}
.kat_component_border_radius_0 {
  --border-radius: 0;
}
.tooltip_info_wrapper {
  display: flex;
  gap: 4px;
  flex-direction: row;
  justify-content: flex-end;
}
// Colors by Flo https://flo.design.a2z.com/tools/color_system
.color_crimson {
  color: #CC0C39
}

.color_eastern {
  color: #008296
}

.color_golden{
  color: #FFC400
}

.color_fiji{
  color: #538000
}

.color_nordic{
  color: #002F36
}

.color_crimson{
  color: #CC0C39
}

body {
  margin: 0;
}
