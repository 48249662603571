.root {
  margin: 16px;
  margin-top: 36px;
}

.code_block{
  display: flex;
  justify-content: flex-end;
}

.copy_button{
  opacity: 0.5;
}
.copy_button:hover{
  opacity: 1;
  cursor: pointer;
  transition-delay: 50ms;
}

.action_button{
  margin: 8px;
}

.header {
  margin: 8px;
}