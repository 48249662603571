.alert_default_style {
  transition: all 500ms ease-in-out 0ms;
  opacity: 0;
  right: -100%;
}

.alert_static_style {
  margin-top: 8px;
  position: relative;
}

.alert_entering,
.alert_exiting {
  opacity: 0;
  right: -100%;
}

.alert_entered,
.alert_exited {
  opacity: 1;
  right: 0;
}

.alert_list_box {
  position: absolute;
  z-index: 99999;
  right: 0;
  width: 400px;
  top: 10px;
}

.alert_black_link_color {
  color: #002f36;
  &:link {
    color: #002f36;
  }
}

